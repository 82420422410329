import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/jon/projects/shanti-aid-gatsby/src/components/DefaultNewsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Generous Monetary Donation from Western Power Distribution`}</h1>
    <hr></hr>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "498px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bb8e49bb7f1171cac2e51ab63941de93/79e1b/western_power_logo.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0klEQVQI12P4Txz4++/f////H755HjulLn9BT/a8rpSZLQzt7e3d3d1NTU29vb3t7e2NjY3Nzc2NjY379u2bOHHihAkT+vr6+vv72zs6Wpqa5i5d1L51ce6CnpTZbalz2hlmz569ePHiWbNmLV68eM2aNdOmTZs6dWpNTc3mzZsXLVrU2dnZ3d3d1tY2adKk+rr66bNmrj60c+bWVVM3r5i1fQ0Dpgv//PmTmZmZlZUVFxdXW1sbHx+flpaWlZUVFRVVWFBYUVYeERqWFJ/g5uwCADNynxK/Kq3jAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "western power contribution",
            "title": "western power contribution",
            "src": "/static/bb8e49bb7f1171cac2e51ab63941de93/79e1b/western_power_logo.png",
            "srcSet": ["/static/bb8e49bb7f1171cac2e51ab63941de93/772e8/western_power_logo.png 200w", "/static/bb8e49bb7f1171cac2e51ab63941de93/e17e5/western_power_logo.png 400w", "/static/bb8e49bb7f1171cac2e51ab63941de93/79e1b/western_power_logo.png 498w"],
            "sizes": "(max-width: 498px) 100vw, 498px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <hr></hr>
    <p>{`Thank you to Western Power Distribution for you generous monetary donation. To support vulnerable women and children with food parcels. `}</p>
    <p>{`Thank you to @starsupermarket_birmingham `}</p>
    <p>{`For your support with providing and helping us with our weekly shopping so we can distribute these food parcels within the community`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8cd7f5ce5fb613d023ff368e260c4ab8/212bf/western-power.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUCAwQB/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAIDAf/aAAwDAQACEAMQAAABumskrNBQbPRVu2RujOgf/8QAHhAAAgMAAQUAAAAAAAAAAAAAAQIAAxEEEhMiMTP/2gAIAQEAAQUC76swvURblYL4BXSGvSbNjkdKls4/rBLvr//EABgRAAMBAQAAAAAAAAAAAAAAAAABEQIS/9oACAEDAQE/AZlIgzk//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREBL/2gAIAQIBAT8Bp0hKZ//EACAQAAIBAwQDAAAAAAAAAAAAAAABAhExYRIhQZEiI3H/2gAIAQEABj8CcV2O+xVC8JZRJuz4PWlQpFpZNMtLyjjsl9LIZ//EABwQAQACAwEBAQAAAAAAAAAAAAEAESExQXFhwf/aAAgBAQABPyEDV0XTUAaYczk5+yireFipui2rPIbvrqR1sGGx3hl1CYQJTWCVD8ITST//2gAMAwEAAgADAAAAEODzsP/EABgRAAMBAQAAAAAAAAAAAAAAAAABIRFR/9oACAEDAQE/ELDrNcKjMH//xAAYEQADAQEAAAAAAAAAAAAAAAAAASFhEf/aAAgBAgEBPxB3hqKtHT//xAAdEAEBAAIDAQEBAAAAAAAAAAABEQAhMUFxUaHh/9oACAEBAAE/EHfbpLp1ckxX8X+53hHkCe5VkEEQktHFgBtQz4Zy95uCOqF71vGTI0iPtcsRebiu/wB+414FuwYCVTBOM3BvTkgADACZ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "western power contribution",
            "title": "western power contribution",
            "src": "/static/8cd7f5ce5fb613d023ff368e260c4ab8/212bf/western-power.jpg",
            "srcSet": ["/static/8cd7f5ce5fb613d023ff368e260c4ab8/e07e9/western-power.jpg 200w", "/static/8cd7f5ce5fb613d023ff368e260c4ab8/066f9/western-power.jpg 400w", "/static/8cd7f5ce5fb613d023ff368e260c4ab8/212bf/western-power.jpg 768w"],
            "sizes": "(max-width: 768px) 100vw, 768px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <hr></hr>
    <p>{`Thank you to Western Power Distribution for you generous monetary donation. To support vulnerable women and children with food parcels. `}</p>
    <p>{`Thank you to @tesco West Bromwich  `}</p>
    <p>{`For your support with providing and helping us with our weekly shopping so we can distribute these food parcels within the community`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c7c96692b1ad87d5efc714430d3bf27f/212bf/western-power-tesco.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAwAEAQL/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAVxv3Ogil3eVbNyzVH//xAAcEAACAgMBAQAAAAAAAAAAAAABAgADERITITL/2gAIAQEAAQUCWNUhnFWNdjCFE2sU1N1rIymSu8ET7Ph//8QAFxEAAwEAAAAAAAAAAAAAAAAAABAhMf/aAAgBAwEBPwGEMX//xAAXEQADAQAAAAAAAAAAAAAAAAAAASES/9oACAECAQE/AaUa0yn/xAAdEAACAQQDAAAAAAAAAAAAAAAAAREQEiExAkGh/9oACAEBAAY/Ah5gm/wzxbRbOy3ZHQsaJmip/8QAGxABAQEBAQEBAQAAAAAAAAAAAREAITFBYcH/2gAIAQEAAT8hq2E/TI8JsmVK9wwoPGebuk/G6Q8XcuL4jvkf4wrQgTEQQcRQ4b//2gAMAwEAAgADAAAAEDvAMP/EABkRAAIDAQAAAAAAAAAAAAAAAAABEBEhMf/aAAgBAwEBPxBa2PBSZ//EABgRAAMBAQAAAAAAAAAAAAAAAAABESFR/9oACAECAQE/EHlRk7NEqZT/xAAeEAEAAwACAwEBAAAAAAAAAAABABEhMUFRYaGBkf/aAAgBAQABPxBYko3oQUuikPnv6fspGq06UBXyFIw9O2O+I0KwVbLH3ey0cIJlD0xAEKo1kEQJ5N8Nw/s36FACXQe+4AgB4Mh4xdEuECA4HE//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "western power and tesco contribution",
            "title": "western power and tesco contribution",
            "src": "/static/c7c96692b1ad87d5efc714430d3bf27f/212bf/western-power-tesco.jpg",
            "srcSet": ["/static/c7c96692b1ad87d5efc714430d3bf27f/e07e9/western-power-tesco.jpg 200w", "/static/c7c96692b1ad87d5efc714430d3bf27f/066f9/western-power-tesco.jpg 400w", "/static/c7c96692b1ad87d5efc714430d3bf27f/212bf/western-power-tesco.jpg 768w"],
            "sizes": "(max-width: 768px) 100vw, 768px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <hr></hr>
    <p>{`Thank you to Western Power Distribution for you generous monetary donation. To support vulnerable women and children with food parcels. `}</p>
    <p>{`Thank you to `}</p>
    <p>{`@starsupermarket_birmingham `}</p>
    <p>{`@aldi Bearwood `}</p>
    <p>{`For your support with providing and helping us with our weekly shopping so we can distribute these food parcels within the community`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5c2b91459b4682bc994ff4773477e123/1c7bc/western-power-aldi.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.50000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAEDBAX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAHOurlSGsy8aRKBh//EABwQAAICAgMAAAAAAAAAAAAAAAECAAMQExEUIv/aAAgBAQABBQLe3C3NOywhq8qgU66mwMf/xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIQEv/aAAgBAwEBPwFRMO//xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIQEv/aAAgBAgEBPwFyRtX/AP/EABwQAAICAgMAAAAAAAAAAAAAAAARASECIDEzof/aAAgBAQAGPwK6fCLZfknZDHlkOZjT/8QAGxABAQEAAwEBAAAAAAAAAAAAAREAITFRIHH/2gAIAQEAAT8h5V10K64Wjy97gguQTItBlA8wOh+Pw//aAAwDAQACAAMAAAAQFCdD/8QAGBEAAgMAAAAAAAAAAAAAAAAAAREAECH/2gAIAQMBAT8QIlC7Df8A/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAEQESExQf/aAAgBAgEBPxDmFXlD1H//xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhMUFxkVFh/9oACAEBAAE/EEXZ1WivcZhjE1wv2ILBViNV5GlVSAgGOJUA0Ci38wpUMqdCBjqM3jt7n//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "western power and tesco contribution",
            "title": "western power and tesco contribution",
            "src": "/static/5c2b91459b4682bc994ff4773477e123/4b190/western-power-aldi.jpg",
            "srcSet": ["/static/5c2b91459b4682bc994ff4773477e123/e07e9/western-power-aldi.jpg 200w", "/static/5c2b91459b4682bc994ff4773477e123/066f9/western-power-aldi.jpg 400w", "/static/5c2b91459b4682bc994ff4773477e123/4b190/western-power-aldi.jpg 800w", "/static/5c2b91459b4682bc994ff4773477e123/1c7bc/western-power-aldi.jpg 1023w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      